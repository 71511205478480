
import * as React from 'react';
import { createDirectus, rest, readItems } from '@directus/sdk';
import { ComputedTradeValues, Trade } from '../models';
import TradeCard from './trade_card';

import dayjs from 'dayjs'

import { Col, List, Row, Segmented, Tag, DatePicker } from 'antd';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { webStorage } from 'src/utils/web_storage';

var isBetween = require( 'dayjs/plugin/isBetween' )
dayjs.extend( isBetween )

interface Schema {
    trades: Trade[];
}

interface Props {
    activeMode: boolean;
}

export default function TradesList( props: Props ) {

    const intl = useIntl()

    const client = createDirectus<Schema>( 'https://api.curlybrace.fr' ).with( rest() );
    const [ trades, setTrades ] = React.useState<Trade[]>( [] );

    const [ accountMode, setAccountMode ] = React.useState<'small' | 'full'>( webStorage.getAccountMode() || 'small' );
    const [ statusShown, setStatusShown ] = React.useState<'all' | 'active' | 'closed' | 'expired'>( 'active' );
    const [ historyStartDate, setHistoryStartDate ] = React.useState<dayjs.Dayjs>( null );
    const [ historyEndDate, setHistoryEndDate ] = React.useState<dayjs.Dayjs>( null );

    React.useEffect( () => {
        fetchTrades();
    }, [ accountMode, props.activeMode, statusShown, historyEndDate ] );

    React.useEffect( () => {
        if ( props.activeMode === false ) {
            setStatusShown( 'all' )
        }
    }, [ props.activeMode ] );

    const fetchTrades = async () => {
        try {

            let filter: any = props.activeMode ? {
                expiration_date: {
                    "_gte": dayjs().startOf( 'month' )
                }
            } : {}

            if ( accountMode === 'small' ) {
                filter.in_small_account = {
                    "_eq": accountMode === 'small'
                }
            }

            const result = await client.request<Trade[]>( ( readItems as any )( 'trades', {
                fields: [ '*', {
                    ticker: [ '*' ]
                } ],
                filter
            } ) )

            const computedTrades = result.map( item => {

                const multiplier = item.contracts * 100
                const required_capital = Math.abs( item.strike - ( item.spread_strike || 0 ) ) * multiplier
                const trade_length_in_days = dayjs( item.expiration_date ).diff( item.open_date, 'days' )
                const trade_max_profit = ( item.premium * multiplier ) - item.open_commission
                const adjusted_30_days_yield = trade_max_profit / required_capital / trade_length_in_days * 30

                let status = 'active'

                if ( !!item.close_price ) {
                    status = 'closed'
                } else if ( dayjs( item.expiration_date ).isBefore( dayjs(), 'day' ) ) {
                    status = 'expired'
                }

                let pnl = trade_max_profit

                if ( status === 'closed' ) {
                    pnl = ( ( item.premium - item.close_price ) * multiplier ) - item.open_commission - ( item.close_commission || 0 )
                }

                if ( status === 'active' ) {
                    if ( item.ticker?.last_price ) {
                        if ( item.type === 'CALL' && item.ticker?.last_price > item.strike ) {
                            pnl = ( ( item.strike - item.ticker?.last_price + item.premium ) * multiplier ) - item.open_commission
                        }
                        if ( item.type === 'PUT' && item.ticker?.last_price < item.strike ) {
                            pnl = ( ( item.ticker?.last_price - item.strike + item.premium ) * multiplier ) - item.open_commission
                        }
                    }
                }

                const breakeven_price = ( item.type === 'CALL' ? ( item.strike + item.premium ) : ( item.strike - item.premium ) ) - ( item.open_commission / multiplier )

                let distance_percent = 0

                if ( item.ticker?.last_price ) {
                    if ( item.type === 'PUT' ) {
                        distance_percent = ( item.ticker?.last_price - breakeven_price ) / item.ticker?.last_price
                    }
                    if ( item.type === 'CALL' ) {
                        distance_percent = ( breakeven_price - item.ticker?.last_price ) / item.ticker?.last_price
                    }
                }

                return Object.assign( {}, item, {
                    required_capital,
                    adjusted_30_days_yield,
                    breakeven_price,
                    distance_to_breakeven: distance_percent,
                    pnl,
                    status
                } as ComputedTradeValues )
            } ).sort( ( a, b ) => {

                if ( a.status !== b.status ) {

                    if ( a.status === 'active' ) {
                        return -1
                    }

                    if ( b.status === 'active' ) {
                        return 1
                    }

                    return dayjs( b.expiration_date ).diff( a.expiration_date )
                }

                return dayjs( b.expiration_date ).diff( a.expiration_date )

            } )

            let statusFilteredTrades = computedTrades.filter( item => {
                if ( statusShown === 'all' ) {
                    return true
                }
                return item.status === statusShown
            } )

            let datesFilteredTrades = ( !!historyStartDate && !props.activeMode ) ? statusFilteredTrades.filter( item => {

                if ( item.close_date ) {
                    return ( dayjs( item.close_date ) as any ).isBetween( historyStartDate, historyEndDate, 'day', '[]' )
                }

                if ( item.expiration_date ) {
                    return ( dayjs( item.expiration_date ) as any ).isBetween( historyStartDate, historyEndDate, 'day', '[]' )
                }

                return false

            } ) : statusFilteredTrades

            setTrades( datesFilteredTrades )

        } catch ( error ) {
            console.error( 'Error fetching trades:', error )
        }
    };

    const total_pnl = getTotalPnL( trades )
    const total_commissions = getTotalCommissions( trades )

    const capital = accountMode === 'small' ? 5000 : webStorage.getFullAccountSize()

    const cardStyle: React.CSSProperties = { background: 'white', padding: '10px 15px', height: 62, display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }

    return (
        <React.Fragment>
            <div className="mgb-15" style={{ marginLeft: window.innerWidth < 768 ? 0 : 54, marginRight: 36 }}>
                <Row gutter={[ 10, 10 ]} justify="space-between">
                    <Col style={{ marginLeft: window.innerWidth < 768 ? 70 : 0 }}>
                        <div style={{ height: 62, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h3 className="tfont mg-0" style={{ textTransform: 'capitalize' }}>
                                <span style={{ verticalAlign: 'middle' }}>{props.activeMode ? dayjs().format( 'MMMM YYYY' ) : <FormattedMessage id="history" />}</span>
                            </h3>
                            {
                                props.activeMode ? null : (
                                    <div style={{ marginTop: 5 }}>
                                        <DatePicker.RangePicker
                                            size="small"
                                            value={[ historyStartDate, historyEndDate ]}
                                            onChange={( dates ) => {
                                                setHistoryStartDate( dates && dates.length ? dates[ 0 ] : null )
                                                setHistoryEndDate( dates && dates.length ? dates[ 1 ] : null )
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </Col>
                    <Col>
                        <Row gutter={[ 15, 15 ]} justify="end">
                            <Col flex={1}>
                                <div className="bordered-1 text-center" style={cardStyle}>
                                    <div className="small-label mgb-5"><FormattedMessage id="stats_status_shown" /></div>
                                    <Segmented size="small" style={{ margin: 'auto' }} options={[
                                        {
                                            value: 'active',
                                            label: intl.formatMessage( { id: 'trade_status_active' } )
                                        },
                                        {
                                            value: 'closed',
                                            label: intl.formatMessage( { id: 'trade_status_closed' } )
                                        },
                                        {
                                            value: 'expired',
                                            label: intl.formatMessage( { id: 'trade_status_expired' } )
                                        },
                                        {
                                            value: 'all',
                                            label: intl.formatMessage( { id: 'all' } )
                                        }
                                    ]} value={statusShown} onChange={( status: any ) => {
                                        setStatusShown( status )
                                    }} />
                                </div>
                            </Col>
                            <Col flex={1}>
                                <div className="bordered-1 text-center" style={cardStyle}>
                                    <div className="small-label mgb-5"><FormattedMessage id="stats_account_size" /></div>
                                    <Segmented size="small" style={{ margin: 'auto' }} options={[
                                        {
                                            value: 'small',
                                            label: '5K'
                                        },
                                        {
                                            value: 'full',
                                            label: '∞'
                                        }
                                    ]} value={accountMode} onChange={( mode: any ) => {
                                        setAccountMode( mode )
                                        webStorage.storeAccountMode( mode )
                                    }} />
                                </div>
                            </Col>
                            <Col flex={1}>
                                <div className="bordered-1 text-center" style={cardStyle}>
                                    <div className="small-label mgb-5"><FormattedMessage id="stats_commissions" /></div>
                                    <div>
                                        <Tag className="w-700 mgr-0"
                                            color="orange">
                                            <FormattedNumber
                                                value={total_commissions}
                                                style="currency"
                                                currency="USD"
                                                currencyDisplay="narrowSymbol"
                                            />
                                        </Tag>
                                    </div>
                                </div>
                            </Col>
                            <Col flex={1}>
                                <div className="bordered-1 text-center" style={cardStyle}>
                                    <div className="small-label mgb-5"><FormattedMessage id="stats_performance" /></div>
                                    <div>
                                        <Tag className="w-700 mgr-0"
                                            color={( total_pnl / capital ) >= 0 ? 'lime' : 'volcano'}>
                                            <FormattedNumber
                                                value={total_pnl / capital}
                                                style="percent"
                                                minimumFractionDigits={2}
                                            />
                                        </Tag>
                                    </div>
                                </div>
                            </Col>
                            <Col flex={1}>
                                <div className="bordered-1 text-center" style={cardStyle}>
                                    <div className="small-label mgb-5"><FormattedMessage id="stats_pnl" /></div>
                                    <div>
                                        <Tag className="w-700 mgr-0"
                                            color={total_pnl >= 0 ? 'lime' : 'volcano'}>
                                            <FormattedNumber
                                                value={total_pnl}
                                                style="currency"
                                                currency="USD"
                                                currencyDisplay="narrowSymbol"
                                            />
                                        </Tag>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div >
            <List
                dataSource={trades}
                rowKey={item => item.id}
                renderItem={item => <TradeCard data={item} />}
                pagination={{
                    showTotal: total => <div className="lower"><span className="w-700">{total}</span> <FormattedMessage id="trades" /></div>
                }}
            />
        </React.Fragment >
    );
}

function getTotalPnL( trades: Trade[] ) {
    return trades.reduce( ( acc, val ) => {
        acc += val.pnl;
        return acc;
    }, 0 )
}

function getTotalCommissions( trades: Trade[] ) {
    return trades.reduce( ( acc, val ) => {
        acc += val.open_commission + ( val.close_commission || 0 );
        return acc;
    }, 0 )
}