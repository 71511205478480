import React, { useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ThunderboltOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import { Layout, Menu, ConfigProvider, Segmented, FloatButton } from 'antd';
import TradesList from './components/trades_list';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { webStorage } from './utils/web_storage';

var packageJson = require( "../package.json" );

export const UI_VERSION = packageJson.version;

const { Sider, Content } = Layout;

const App: React.FC = () => {

    const [ collapsed, setCollapsed ] = useState( window.innerWidth < 768 );
    const [ selectedMenuKey, setSelectedMenuKey ] = useState<string>( 'active' );


    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#d9a366',
                    borderRadius: 5,
                }
            }}>
            <Layout>

                <FloatButton
                    type="primary" className="animated"
                    style={{ left: collapsed ? 15 : 220, top: 26, height: 40 }}
                    onClick={() => { setCollapsed( !collapsed ) }}
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                />

                <Sider onCollapse={collapsed => setCollapsed( collapsed )} collapsible collapsed={collapsed} width={240} collapsedWidth={0} trigger={null}>

                    <div style={{ height: 180, display: 'flex', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ fontFamily: 'monospace', fontSize: 24 }}>curlybrace</div>
                        <div className="tfont" style={{ marginTop: 8, fontSize: 16, textTransform: 'uppercase', fontWeight: 300 }}>TRACKING</div>
                    </div>

                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={[ selectedMenuKey ]}
                        onSelect={info => { setSelectedMenuKey( info.key ) }}
                        items={[
                            {
                                key: 'active',
                                icon: <ThunderboltOutlined />,
                                label: dayjs().format( 'MMMM YYYY' ),
                                style: { textTransform: 'capitalize' },
                            },
                            {
                                key: 'history',
                                icon: <HistoryOutlined />,
                                label: <FormattedMessage id="history" />
                            },
                        ]}
                    />

                    <div className="rel font-90 text-center mgb-10" style={{ top: window.innerHeight - 340, width: '100%' }}>
                        <div className="mgb-10">
                            <Segmented className="tfont w-600" style={{ fontSize: 10, backgroundColor: '#c9c9cf', color: 'black' }} size="small" options={[
                                {
                                    value: 'en-US',
                                    label: 'EN'
                                },
                                {
                                    value: 'fr-FR',
                                    label: 'FR'
                                }
                            ]} value={webStorage.getLanguage()} onChange={( language: any ) => {
                                webStorage.storeLanguage( language )
                                window.location.reload()
                            }} />
                        </div>
                        <div className="w-700" style={{ color: '#ccc', fontSize: 12 }}>{UI_VERSION}</div>
                    </div>

                </Sider>
                <Content
                    style={{
                        padding: 15,
                        paddingBottom: 45,
                        minHeight: '100vh'
                    }}>

                    <TradesList
                        activeMode={selectedMenuKey === 'active'}
                    />

                </Content>
            </Layout>
        </ConfigProvider >
    );
};

export default App;