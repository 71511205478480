
import * as React from 'react';

import { Trade } from '../models';

import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Col, Row, Tag, Drawer, Divider, Popover, Badge, Tooltip } from 'antd';

import dayjs from 'dayjs'

import {
    MoreOutlined,
    SlidersOutlined
} from '@ant-design/icons';

var localizedFormat = require( 'dayjs/plugin/localizedFormat' )
var relativeTime = require( 'dayjs/plugin/relativeTime' )
dayjs.extend( localizedFormat )
dayjs.extend( relativeTime )

interface Props {
    data: Trade
}


export default function TradeCard( props: Props ) {

    const { data } = props

    const [ open, setOpen ] = React.useState<boolean>( false )

    const intl = useIntl()

    return (
        <div className="bordered-1 mgb-15" style={{ display: 'flex', background: '#fefefe', borderRadius: 5 }} onDoubleClick={() => { setOpen( true ) }}>
            <div style={{ flex: 1, paddingLeft: 30, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
                <Row gutter={[ 20, 15 ]} align="top">
                    <Col xs={24} lg={4} className="text-center" style={{ alignSelf: 'center' }}>
                        <Row gutter={[ 12, 10 ]} align="middle">
                            <Col>
                                <div
                                    style={{
                                        width: 26, height: 26,
                                        backgroundImage: `url('https://api.curlybrace.fr/assets/${ data.ticker?.logo })'`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        backgroundSize: 'contain',
                                        borderRadius: 26
                                    }}
                                />
                            </Col>
                            <Col style={{ textAlign: 'left' }}>
                                <div className="font-130 w-700 primary">{data.ticker?.symbol}</div>
                            </Col>
                        </Row>

                    </Col>
                    <Col flex={1}>
                        <Row gutter={[ 20, 10 ]}>
                            <Col xs={12} lg={4} style={{ alignSelf: 'center' }}>
                                <Tooltip title={data.status === 'closed' ? `${ intl.formatMessage( { id: 'trade_close_date' } ) } → ${ intl.formatDate( data.close_date ) }` : null}>
                                    {data.status === 'active' ? <div style={{ transform: 'scale(1.5)', display: 'inline-block', marginRight: 10 }}><Badge status="processing" color="cyan" /></div> : null}
                                    {data.status === 'expired' ? <div style={{ transform: 'scale(1.25)', display: 'inline-block', marginRight: 10, filter: 'grayscale(0.4)' }}><Badge color={data.pnl >= 0 ? 'lime' : 'volcano'} /></div> : null}
                                    {data.status === 'closed' ? <div style={{ transform: 'scale(1.25)', display: 'inline-block', marginRight: 10, filter: 'grayscale(0.4)' }}><Badge color={data.pnl >= 0 ? 'lime' : 'volcano'} /></div> : null}
                                    <span className="small-label" style={{ verticalAlign: 1 }}><FormattedMessage id={`trade_status_${ data.status }`} /></span>
                                </Tooltip>
                            </Col>
                            <Col xs={12} lg={3}>
                                {renderValue( data, 'type' )}
                            </Col>
                            <Col xs={12} lg={6}>
                                {renderValue( data, 'expiration_date', 'date' )}
                            </Col>
                            <Col xs={12} lg={6}>
                                <div className="small-label">Strike</div>
                                <Row align="middle">
                                    <Col>
                                        <div className="w-600">
                                            <FormattedNumber
                                                value={data.strike}
                                                style="currency"
                                                currency="USD"
                                                currencyDisplay="narrowSymbol"
                                            />
                                        </div>
                                    </Col>
                                    {
                                        data.spread_strike ? (
                                            <Col>
                                                <div className="font-80 italic" style={{ marginLeft: 10 }}>
                                                    <span style={{ marginRight: 2 }}>+</span>
                                                    <FormattedNumber
                                                        value={data.spread_strike}
                                                        style="currency"
                                                        currency="USD"
                                                        currencyDisplay="narrowSymbol"
                                                    />
                                                </div>
                                            </Col>
                                        ) : null
                                    }
                                </Row>
                            </Col>
                            <Col xs={12} lg={3}>
                                {renderValue( data, 'premium', 'currency' )}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={4}>
                        <div className="text-right">
                            {renderPnL( data )}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="bordered-1-left clickable hover-highlight" style={{ backgroundColor: '#001529dd', color: 'white', width: 36, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: 3, borderBottomRightRadius: 3 }}
                onClick={() => { setOpen( !open ) }}>
                <MoreOutlined style={{ fontSize: 28 }} />
            </div>
            <Drawer title={`${ data.ticker?.symbol } • ${ data.strike } ${ data.type } • ${ data.expiration_date }`} placement="right" onClose={() => { setOpen( false ) }} open={open}>
                <div className="mgt-20">
                    <Row gutter={[ 20, 20 ]}>
                        <Col xs={12}>
                            <div className="font-130 w-700 primary">{data.ticker?.symbol}</div>
                        </Col>
                        <Col xs={12}>
                            <div className="small-label"><FormattedMessage id="trade_status" /></div>
                            {data.status === 'active' ? <div style={{ transform: 'scale(1.5)', display: 'inline-block', marginRight: 10 }}><Badge status="processing" color="cyan" /></div> : null}
                            {data.status === 'expired' ? <div style={{ transform: 'scale(1.25)', display: 'inline-block', marginRight: 10, filter: 'grayscale(0.4)' }}><Badge color={data.pnl >= 0 ? 'lime' : 'volcano'} /></div> : null}
                            {data.status === 'closed' ? <div style={{ transform: 'scale(1.25)', display: 'inline-block', marginRight: 10, filter: 'grayscale(0.4)' }}><Badge color={data.pnl >= 0 ? 'lime' : 'volcano'} /></div> : null}
                            <span className="small-label" style={{ verticalAlign: 1, color: 'black', fontWeight: 600 }}><FormattedMessage id={`trade_status_${ data.status }`} /></span>
                        </Col>
                        <Col xs={12}>
                            {renderValue( data, 'type' )}
                            {
                                data.spread_strike ? (
                                    <div className="mgt-5 primary font-90 tfont">{data.type === 'PUT' ? 'Bull Put Credit Spread' : 'Bear Call Credit Spread'}</div>
                                ) : null
                            }
                        </Col>
                        <Col xs={12}>
                            {renderValue( data, 'expiration_date', 'date' )}
                        </Col>
                        <Col xs={12}>
                            <div className="small-label"><FormattedMessage id="trade_strike" /></div>
                            <Row align="middle">
                                <Col>
                                    <div className="w-600">
                                        <FormattedNumber
                                            value={data.strike}
                                            style="currency"
                                            currency="USD"
                                            currencyDisplay="narrowSymbol"
                                        />
                                    </div>
                                </Col>
                                {
                                    data.spread_strike ? (
                                        <Col>
                                            <div className="font-80 italic" style={{ marginLeft: 10 }}>
                                                <span style={{ marginRight: 2 }}>+</span>
                                                <FormattedNumber
                                                    value={data.spread_strike}
                                                    style="currency"
                                                    currency="USD"
                                                    currencyDisplay="narrowSymbol"
                                                />
                                            </div>
                                        </Col>
                                    ) : null
                                }
                            </Row>
                        </Col>
                        <Col xs={12}>
                            {renderValue( data, 'premium', 'currency' )}
                        </Col>
                        <Col xs={12}>
                            {renderValue( data, 'contracts' )}
                        </Col>
                        <Divider><span className="primary"><FormattedMessage id="trade_info" /></span></Divider>
                        <Col xs={12}>
                            {renderValue( data, 'required_capital', 'currency' )}
                        </Col>
                        <Col xs={12}>
                            {renderValue( data, 'adjusted_30_days_yield', 'percent' )}
                        </Col>
                        <Col xs={12}>
                            {renderValue( data, 'open_date', 'date' )}
                        </Col>
                        <Col xs={12}>
                            {renderValue( data, 'open_commission', 'currency' )}
                        </Col>
                        {
                            data.close_price ? (
                                <Col xs={12}>
                                    {renderValue( data, 'close_price', 'currency' )}
                                </Col>
                            ) : null
                        }
                        {
                            data.close_date ? (
                                <Col xs={12}>
                                    {renderValue( data, 'close_date', 'date' )}
                                </Col>
                            ) : null
                        }
                        {
                            data.close_commission ? (
                                <Col xs={12}>
                                    {renderValue( data, 'close_commission', 'currency' )}
                                </Col>
                            ) : null
                        }
                        <Divider className="mgt-20 mgb-20">
                            {
                                data.status === 'active' ? (
                                    <Popover content={(
                                        <div className='text-center'>
                                            <div className="label"><FormattedMessage id="trade_last_price" /></div>
                                            <div className="w-700 mgb-10 font-110" style={{ fontFamily: 'monospace, serif' }}>
                                                <FormattedNumber
                                                    value={data.ticker?.last_price}
                                                    style="currency"
                                                    currency="USD"
                                                    currencyDisplay="narrowSymbol"
                                                />
                                            </div>
                                            <div>
                                                <span className="font-90"><FormattedMessage id="trade_last_updated" /></span>
                                                <span className="mgl-5 mgr-5">→</span>
                                                <span className="italic w-500 font-90 primary">{( dayjs as any )( data.ticker?.date_updated ).fromNow()}</span>
                                            </div>
                                        </div>
                                    )}>
                                        <div className="w-700" style={{ fontFamily: 'monospace, serif' }}>
                                            <span className="mgr-15 font-110" style={{ verticalAlign: 1 }}>{data.ticker?.symbol}</span>
                                            <span className="mgr-15 primary"><SlidersOutlined /></span>
                                            <span className="font-130">
                                                <FormattedNumber
                                                    value={data.ticker?.last_price}
                                                    style="currency"
                                                    currency="USD"
                                                    currencyDisplay="narrowSymbol"
                                                />
                                            </span>
                                        </div>
                                    </Popover>
                                ) : null
                            }
                        </Divider>
                        {
                            data.status === 'active' ? (
                                <>
                                    <Col xs={12} className="text-center">
                                        {renderDistanceToBreakevenValue( data )}
                                    </Col>
                                    <Col xs={12} className="text-center" style={{ alignSelf: 'center' }}>
                                        {renderValue( data, 'breakeven_price', 'currency' )}
                                    </Col>
                                </>
                            ) : null
                        }
                        <Col xs={24} className={data.status === 'active' ? 'mgt-15' : null}>
                            <div className="text-center" style={{ transform: 'scale(1.1)' }}>
                                {renderPnL( data )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Drawer>
        </div>
    );
}

function renderPnL( trade: Trade ) {

    return (
        <div>
            <div className="small-label">
                {trade.status === 'active' ? <FormattedMessage id="trade_projected_pnl" /> : <FormattedMessage id="trade_closed_pnl" />}
            </div>
            <Tag className="w-700 mgr-0"
                color={trade.pnl >= 0 ? 'lime' : 'volcano'}>
                <FormattedNumber
                    value={trade.pnl}
                    style="currency"
                    currency="USD"
                    currencyDisplay="narrowSymbol"
                />
            </Tag>
        </div>
    )

}

function renderDistanceToBreakevenValue( trade: Trade ) {

    if ( trade.ticker?.last_price ) {

        let tag_color = null

        if ( trade.distance_to_breakeven >= .2 ) {
            tag_color = 'green'
        }

        if ( trade.distance_to_breakeven < .20 ) {
            tag_color = 'lime'
        }

        if ( trade.distance_to_breakeven <= .10 ) {
            tag_color = 'gold'
        }

        if ( trade.distance_to_breakeven <= .075 ) {
            tag_color = 'orange'
        }

        if ( trade.distance_to_breakeven <= .03 ) {
            tag_color = 'volcano'
        }

        if ( trade.distance_to_breakeven <= .02 ) {
            tag_color = 'red'
        }

        return renderValue( trade, 'distance_to_breakeven', null, (
            <Tag color={tag_color} style={{ width: 80, textAlign: 'center', marginRight: 0 }}>
                <FormattedNumber
                    value={trade.distance_to_breakeven}
                    style="percent"
                    minimumFractionDigits={2}
                />
            </Tag>
        ) )

    }

    return null

}

function renderValue( trade: Trade, key: keyof Trade, type?: 'date' | 'currency' | 'percent', specificDisplay?: any ) {

    let value: any = specificDisplay || trade[ key ]

    let valueDisplay = value

    if ( type === 'date' ) {
        valueDisplay = (
            <FormattedDate
                value={value}
            />
        )
    }

    if ( type === 'currency' ) {
        valueDisplay = (
            <FormattedNumber
                value={value}
                style="currency"
                currency="USD"
                currencyDisplay="narrowSymbol"
            />
        )
    }

    if ( type === 'percent' ) {
        valueDisplay = (
            <FormattedNumber
                value={value}
                style="percent"
                minimumFractionDigits={2}
            />
        )
    }

    return (
        <>
            <div className="small-label"><FormattedMessage id={`trade_${ key }`} /></div>
            <div className="w-600">
                {valueDisplay}
            </div>
        </>
    )

}