
import fr_FR from 'antd/lib/locale/fr_FR'
import en_US from 'antd/lib/locale/en_US'
import { en_wordings, fr_wordings } from 'src/wordings'

import { webStorage } from './web_storage'

export function getLocaleProvider() {

    const language = webStorage.getLanguage( true )

    if ( language === 'fr' ) {
        return fr_FR
    }

    if ( language === 'en' ) {
        return en_US
    }

    return en_US

}

export function getMessages() {

    const language = webStorage.getLanguage( true )

    if ( language === 'fr' ) {
        return fr_wordings
    }

    if ( language === 'en' ) {
        return en_wordings
    }

    return en_wordings

}