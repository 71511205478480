import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import { webStorage } from 'src/utils/web_storage'
import { getLocaleProvider, getMessages } from 'src/utils/language'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'

const language = webStorage.getLanguage()
const localeProvider = getLocaleProvider()
const messages = getMessages()

dayjs.locale( language )

const root = ReactDOM.createRoot(
    document.getElementById( 'root' ) as HTMLElement
);
root.render(
    <React.StrictMode>
        <IntlProvider locale={language} key={localeProvider.locale} messages={messages}>
            <App />
        </IntlProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
