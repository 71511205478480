const wordings: { [ key: string ]: { en: string; fr: string } } = {

    trades: {
        en: 'Trades',
        fr: 'Transactions'
    },
    history: {
        en: 'History',
        fr: 'Historique'
    },
    info: {
        en: 'Info',
        fr: 'Info'
    },
    details: {
        en: 'Details',
        fr: 'Détails'
    },
    all: {
        en: 'All',
        fr: 'Tous'
    },

    trade_status: {
        en: 'Trade status',
        fr: 'Statut du trade'
    },
    trade_status_active: {
        en: 'Active',
        fr: 'Actif'
    },
    trade_status_expired: {
        en: 'Expired',
        fr: 'Expiré'
    },
    trade_status_closed: {
        en: 'Closed',
        fr: 'Clôturé'
    },
    trade_close_date: {
        en: 'Close date',
        fr: 'Date de clôture'
    },
    trade_close_price: {
        en: 'Close price',
        fr: 'Prix de clôture'
    },
    trade_contracts: {
        en: 'Contracts quantity',
        fr: 'Nombre de contrats'
    },
    trade_expiration_date: {
        en: 'Expiration date',
        fr: "Date d'expiration"
    },
    trade_open_date: {
        en: 'Open date',
        fr: "Date d'ouverture"
    },
    trade_premium: {
        en: 'Premium',
        fr: 'Prime'
    },
    trade_spread_strike: {
        en: 'Spread strike',
        fr: "Prix d'exercice secondaire"
    },
    trade_strike: {
        en: 'Strike',
        fr: "Prix d'exercice"
    },
    trade_open_commission: {
        en: 'Open commission',
        fr: "Commission d'ouverture"
    },
    trade_close_commission: {
        en: 'Close commission',
        fr: 'Commission de clôture'
    },
    trade_type: {
        en: 'Type',
        fr: 'Type'
    },
    trade_projected_pnl: {
        en: 'Projected P&L',
        fr: 'Profit projeté'
    },
    trade_closed_pnl: {
        en: 'Realized P&L',
        fr: 'Profit réalisé'
    },
    trade_info: {
        en: 'Additional information',
        fr: 'Informations supplémentaires'
    },
    trade_breakeven_price: {
        en: 'Breakeven price',
        fr: "Prix d'équilibre"
    },
    trade_distance_to_breakeven: {
        en: 'Distance to breakeven',
        fr: "Distance au prix d'équilibre"
    },
    trade_required_capital: {
        en: 'Required capital',
        fr: 'Capital mobilisé'
    },
    trade_adjusted_30_days_yield: {
        en: '30 days adj. yield',
        fr: 'Taux ajusté 30 jours'
    },
    trade_last_price: {
        en: 'Last price',
        fr: 'Dernier prix'
    },
    trade_last_updated: {
        en: 'Updated',
        fr: 'Mis à jour'
    },

    stats_pnl: {
        en: 'Net P&L',
        fr: 'Profit net'
    },
    stats_commissions: {
        en: 'Commissions',
        fr: 'Commissions'
    },
    stats_performance: {
        en: 'Performance',
        fr: 'Performance'
    },
    stats_account_size: {
        en: 'Account size',
        fr: 'Type de compte'
    },
    stats_status_shown: {
        en: 'Status',
        fr: 'Statut'
    },

}

export const fr_wordings = Object.keys( wordings ).reduce( ( acc, key ) => {

    acc[ key ] = wordings[ key ].fr

    return acc

}, {} as { [ key: string ]: string } )

export const en_wordings = Object.keys( wordings ).reduce( ( acc, key ) => {

    acc[ key ] = wordings[ key ].en

    return acc

}, {} as { [ key: string ]: string } )