
declare var navigator: any

const WS_KEYS = {
    LANGUAGE: 'app_language',
    ACCOUNT_MODE: 'account_mode',
    ACCOUNT_SIZE: 'account_size'
}

//exported webStorage utils
export const webStorage = {

    getLanguage,
    storeLanguage,

    getAccountMode,
    storeAccountMode,

    getFullAccountSize,
    storeFullAccountSize,

    clearWebStorage

}


function storeLanguage( language: string ) {
    storeToStorage( WS_KEYS.LANGUAGE, language, true )
}

function getLanguage( withoutRegion?: boolean ): string {
    let storedLanguage = getFromStorage( WS_KEYS.LANGUAGE, true )

    if ( !storedLanguage ) {
        /* Define user's language. Different browsers have the user locale defined
        on different fields on the `navigator` object, so we make sure to account
        for these different by checking all of them */
        storedLanguage = ( navigator.languages && navigator.languages[ 0 ] ) || navigator.language || navigator.userLanguage
        storeLanguage( storedLanguage )
    }

    return withoutRegion ? storedLanguage.toLowerCase().split( /[_-]+/ )[ 0 ] : storedLanguage
}

function getAccountMode(): 'small' | 'full' {
    return getFromStorage( WS_KEYS.ACCOUNT_MODE, true )
}

function storeAccountMode( mode: 'small' | 'full' ) {
    storeToStorage( WS_KEYS.ACCOUNT_MODE, mode, true )
}

function getFullAccountSize(): number {
    return getFromStorage( WS_KEYS.ACCOUNT_SIZE, true ) || 15000
}

function storeFullAccountSize( size: number ) {
    storeToStorage( WS_KEYS.ACCOUNT_SIZE, size, true )
}

//clearing function

function clearWebStorage() {

    //keeping a copy of some fields used for bootstrapping the app    
    const language = getLanguage()

    //clearing all values    
    window.localStorage.clear()
    window.sessionStorage.clear()

    //resetting the saved values
    storeLanguage( language )
}


//utils

function storeToStorage( key: string, value: any, localStorage?: boolean, encoded?: boolean ) {
    const storage = localStorage ? window.localStorage : window.sessionStorage

    if ( storage ) {
        storage.setItem( key, encoded ? btoa( JSON.stringify( value ) ) : JSON.stringify( value ) )
    }
}

function getFromStorage( key: string, localStorage?: boolean, encoded?: boolean ): any {
    const storage = localStorage ? window.localStorage : window.sessionStorage

    if ( storage ) {

        try {
            return storage.getItem( key ) ? JSON.parse( encoded ? atob( storage.getItem( key ) ) : storage.getItem( key ) ) : undefined
        }
        catch ( e ) {
            console.warn( e )
        }
    }

    return undefined
}

function clearFromStorage( key: string, localStorage?: boolean ) {
    const storage = localStorage ? window.localStorage : window.sessionStorage

    if ( storage ) {
        storage.removeItem( key )
    }
}